import "../PagesCss/Default.css";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import ImageContainer from "./ImageContainer";
import axios from "axios"; // Import Axios

const FrenchandQuebecDocuments = () => {
  const currentYear = new Date().getFullYear();
  const [isLoading, setIsLoading] = useState(false);
  console.log("🚀 ~ FrenchandQuebecDocuments ~ setIsLoading:", setIsLoading)
  const navigate = useNavigate();
  const handleNavigate = () => {
    // Function to navigate to the PesModifyRecord route
    window.scrollTo(0, 0);
  };
  const [visitedColors, setVisitedColors] = useState([]);
  const openPDF = async (endPoint, id, fileName) => {

    setVisitedColors([...visitedColors, id]);
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/${endPoint}?EventID=${JSON.parse(localStorage.getItem("eventID")).eventID
      }&User=${JSON.parse(localStorage.getItem("userData")).username}`,
      "_blank"
    );
  };
  const handleLogout = async (e) => {
    console.log("logout99");
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/logout/`)
      .then((response) => {
        localStorage.removeItem("userData");
        navigate("/login");
        console.log("logout", response);
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };

  return (
    <>
      <div id="layoutSidenav">
        <div id="layoutSidenav_content">
          <main>
            <div className="container-xl px-4 mt-4">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card mb-4">
                    <ImageContainer selectedLanguage="en" />
                    <div className="card-body">
                      <div className="container"></div>

                      <h2
                        style={{
                          color: "#0079F4",
                          fontWeight: "bold",
                          marginLeft: "2.5%",
                        }}
                      >
                        French and Quebec Forms
                      </h2>
                      

                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              onClick={() => {
                                openPDF("ClientAgreementFR", 1, "");
                              }}
                              style={{
                                marginBottom: "7px",
                                color:
                                  visitedColors.indexOf(1) === -1
                                    ? "#0079F4"
                                    : "lightblue",
                                cursor: isLoading ? "wait" : "pointer",
                              }}
                            >
                              French Client Agreement
                            </div>
                          </div>
                          <div className="col-md-6" style={{ fontSize: "14px" }}>
                            French Client Agreement and Fax Cover
                          </div>
                        </div>
                      </div>
                      
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              onClick={() => {
                                openPDF("StatementofServiceFR", 2, "");
                              }}
                              style={{
                                marginBottom: "7px",
                                color:
                                  visitedColors.indexOf(2) === -1
                                    ? "#0079F4"
                                    : "lightblue",
                                cursor: isLoading ? "wait" : "pointer",
                              }}
                            >
                              Statement Of Service
                            </div>
                          </div>
                          <div className="col-md-6" style={{ fontSize: "14px" }}>
                            French Statement of Service
                          </div>
                        </div>
                      </div>

                      {/*  */}

                      {/* <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              onClick={() => {
                                openPDF("QPPfr", 3, "");
                              }}
                              style={{
                                color:
                                  visitedColors.indexOf(2) === -1
                                    ? "#0079F4"
                                    : "lightblue",
                                cursor: isLoading ? "wait" : "pointer",
                              }}
                            >
                              French QPP Form
                            </div>
                          </div>
                          <div className="col-md-6">
                            QPP Application for Death and Survivor Benefits.
                          </div>
                        </div>
                      </div> */}

                      {/*  */}

                      {/* <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              onClick={() => {
                                openPDF("QPPSource", 4, "");
                              }}
                              style={{
                                color:
                                  visitedColors.indexOf(2) === -1
                                    ? "#0079F4"
                                    : "lightblue",
                                cursor: isLoading ? "wait" : "pointer",
                              }}
                            >
                              English QPP Form
                            </div>
                          </div>
                          <div className="col-md-6">
                            QPP Application for Death and Survivor Benefits.
                          </div>
                        </div>
                      </div> */}

                      

                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              onClick={() => {
                                openPDF("CRASource_FR", 6, "");
                              }}
                              style={{
                                marginBottom: "7px",
                                color:
                                  visitedColors.indexOf(2) === -1
                                    ? "#0079F4"
                                    : "lightblue",
                                cursor: isLoading ? "wait" : "pointer",
                              }}
                            >
                              French GST/CRA Notification Form
                            </div>
                          </div>
                          <div className="col-md-6" style={{ fontSize: "14px" }}>
                            Re-calculates Child and GST Tax Credit entitlement.
                          </div>
                        </div>
                      </div>

                      

                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              onClick={() => {
                                openPDF("OASNotif_FR", 3, "");
                              }}
                              style={{
                                marginBottom: "7px",
                                color:
                                  visitedColors.indexOf(3) === -1
                                    ? "#0079F4"
                                    : "lightblue",
                                cursor: isLoading ? "wait" : "pointer",
                              }}
                            >
                              French ISP (OAS/CPP) Notification Form{" "}
                            </div>
                          </div>
                          <div className="col-md-6" style={{ fontSize: "14px" }}>
                            Notifies Income Security Programs to prevent
                            overpayment.
                          </div>
                        </div>
                      </div>

                      

                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              onClick={() => {
                                openPDF(
                                  "CPP_Source_FR",
                                  21,
                                  "CPP_Source_FR.pdf"
                                );
                              }}
                              style={{
                                marginBottom: "7px",
                                color:
                                  visitedColors.indexOf(21) === -1
                                    ? "#0079F4"
                                    : "lightblue",
                                cursor: isLoading ? "wait" : "pointer",
                              }}
                            >
                              French CPP Death Benefit Application
                            </div>
                          </div>
                        </div>
                      </div>


                      {/*  */}

                      {/* <br></br> */}


                      {/* <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                            >
                              <a
                                target="_blank"
                                href="/CPP_Instructions.pdf"
                                style={{ color: "#0079F4", cursor: "pointer" }}
                              >French CPP Death Benefit App Instructions</a>
                            </div>
                          </div>
                          <div className="col-md-6"> Print if needed.</div>
                        </div>
                      </div> */}

                      
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              onClick={() => {
                                openPDF("SurvivorSource_FR", 9, "");
                              }}
                              style={{
                                marginBottom: "7px",
                                color:
                                  visitedColors.indexOf(9) === -1
                                    ? "#0079F4"
                                    : "lightblue",
                                cursor: isLoading ? "wait" : "pointer",
                              }}
                            >
                              French CPP Survivor Benefit Application
                            </div>
                          </div>
                          <div className="col-md-6"></div>
                        </div>
                      </div>

                      <hr></hr>
                      <div className="three_button_class mx-2">
                        <a
                          class="stylish-button"
                          style={{ textAlign: "center" }}
                          href="/dignityInput"
                          onClick={handleNavigate}
                        >
                          NEW ENTRY
                        </a>
                        <button
                          style={{ width: "200px" }}
                          class="stylish-buttonSignout"
                          onClick={handleLogout}
                        >
                          Sign Out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Progressive Estate Solutions Inc. {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default FrenchandQuebecDocuments;
