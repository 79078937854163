import "./App.css";
import React, { useEffect, useState } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Login from "./Pages/Login";
import LandingPage from "./Pages/LandingPage";
import Pesdata from "./Pages/Pesdata";
import "react-notifications/lib/notifications.css";
import PesDignityPrint from "./Pages/PesDignityPrint";
import Layout from "./Pages/Layout";
import VerificationCode from "./Pages/VerificationCode";
import PasswordRecovery from "./Pages/PasswordRecovery";
import NewPassword from "./Pages/NewPassword";
import PesModifyRecord from "./Pages/PesModifyRecord";
import EditPesdata from "./Pages/EditPesdata";
import AftercareList from "./Pages/AftercareList";
import PrivacyStatement from "./Pages/PrivacyStatement";
import FrenchandQuebecDocuments from "./Pages/FrenchandQuebecDocuments";
import Training from "./Pages/Training";
import { useIdleTimer } from "react-idle-timer";
import LandingPageOld from "./Pages/LandingPageOld";
import AftercareList365 from "./Pages/AftercareList365";
import useInactivity from "./utils/useInactivity";
function App() {
  useInactivity(20 * 60 * 1000);

  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  useEffect(() => {
    if (!localStorage.getItem("showMessage")) {
      localStorage.setItem("showMessage", 1);
    }
  }, []);

  const onIdle = () => {
    setState("Idle");
    if (
      localStorage.getItem("showMessage") &&
      localStorage.getItem("showMessage") === 1
    ) {
      localStorage.setItem("showMessage", 0);
      NotificationManager.error(
        "For security reasons, your session has been terminated due to inactivity. Please log in again.",
        "",
        0,
        () => {
          localStorage.setItem("showMessage", 1);
        }
      );
    }
    localStorage.removeItem("userData");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1800_000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      {" "}
      <NotificationContainer />
      {/* <>
        <p>{remaining} seconds remaining</p>
      </> */}
      <div>
        <BrowserRouter>
          <Layout>
            <Routes>
              {" "}
              <Route path="/" element={<LandingPage />} />
              <Route path="/LandingPageOld" element={<LandingPageOld />} />
              <Route path="/login" element={<Login />} />
              {/* <header> */}
              <>
                {JSON.parse(localStorage.getItem("userData")) &&
                  JSON.parse(localStorage.getItem("userData")).RoutingID && (
                    // {true && (
                    <>
                      <Route path="/training" element={<Training />} />

                      <Route
                        path="/dignityAftercare"
                        element={<AftercareList />}
                      />

                      <Route
                        path="/dignityAftercare365"
                        element={<AftercareList365 />}
                      />
                      <Route
                        path="/dignityModify"
                        element={<PesModifyRecord />}
                      />
                      <Route
                        path="/dignityModifyRecord/:eventID"
                        element={<EditPesdata />}
                      />
                      <Route
                        path="/PasswordRecovery"
                        element={<PasswordRecovery />}
                      />
                      <Route
                        path="/VerificationCode"
                        element={<VerificationCode />}
                      />
                      <Route path="/NewPassword" element={<NewPassword />} />
                      <Route
                        path="/PrivacyStatement"
                        element={<PrivacyStatement />}
                      />
                      <Route
                        path="/DignityPrint"
                        element={<PesDignityPrint />}
                      />
                      <Route path="/dignityInput" element={<Pesdata />} />
                      <Route
                        path="/FrenchandQuebecDocuments"
                        element={<FrenchandQuebecDocuments />}
                      />
                    </>
                  )}
                <Route path="*" element={<Navigate to="/login" replace />} />
              </>
            </Routes>
          </Layout>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
