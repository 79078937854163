import { useEffect, useRef, useState } from "react";

const useInactivity = (timeout = 20 * 60 * 1000) => {
  console.log("🚀 ~ useInactivity ~ timeout:", timeout)
  
  const [labels, setLabels] = useState(false);
  const timeoutRef = useRef(null);
  const lastActivityTimeRef = useRef(Date.now());

  const handleInactivity = () => {
    const timeElapsed = Date.now() - lastActivityTimeRef.current;
    localStorage.removeItem("userData");
    resetTimeout();
    console.log(`Inactivity detected after ${timeElapsed / 1000} seconds`);
    alert(
      "For security reasons, your session has been terminated due to inactivity. Please log in again."
    );

    
    setLabels(!labels);
    setTimeout(() => {
      window.location = "/login";
    }, 2000);
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(handleInactivity, timeout);
    lastActivityTimeRef.current = Date.now(); // Reset last activity time
  };

  const handleActivity = () => {
    console.log("Activity detected, resetting timeout");
    resetTimeout();
  };

  useEffect(() => {
    resetTimeout();

    const events = ["mousemove", "mousedown", "keypress", "scroll"];
    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeout]);

  return null;
};

export default useInactivity;
